import axios from 'axios';
import { Socket, io } from 'socket.io-client'
import { type DocumentFolder, type Document, type MessageDto, type DocumentListDto, MemberRole, type HomeSearchResult } from '~/types';
import type { SourceType } from './types/knowledgeService';
import type { ChatSource } from './types/documentService';


export enum ChatStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    ANALYZING = 'analyzing',
    RESEARCHING = 'researching',
}
export interface Chat {
    id: string;
    name: string;
    updatedAt: string;
    language: string;
    status: ChatStatus;
    style: string;
    enableWebSearch: boolean;
    enableDocumentSearch: boolean;
}

export interface DatasetSourceDetails {
    datasetVersionId: string;
    description: string;
    name: string;
    filterModel?: object;
    datasetId?: string;
}

export interface WebsiteSoureDetails {
    url: string;
}

export interface PDFSourceDetails {
    knowledgeSourceId: string;
}

export interface Source {
    id: string;
    name: string;
    description: string;
    type: SourceType;
    createdAt?: string | Date;
    updatedAt?: string | Date;
    knowledgeSourceId: string;
    knowledgeSourceVersionId: string;
    data: string;
}

export interface StyleOption {
    name: string;
    value: string;
    description: string;
}

export interface UserImage {
    id: string;
    createdAt: string | Date;
    updatedAt: string | Date;
    owner: string;
    url: string;
}

export interface SelectedSource {
    ChatId: string;
    SourceId: string;
    createdAt: string;
    id: string;
    data: string;
    originalData: string;
    filterModel: object | null;
    updatedAt: string;
}

export function useDocumentService() {
    const config = useRuntimeConfig();
    const { toggleDialog } = useAppStore();
    const api = axios.create({
        baseURL: `${config.public.baseUrl}/api/documents`,
        headers: useRequestHeaders(['cookie'])
    });

    api.interceptors.response.use(
        response => response,
        error => {
            if (error.response && error.response.status === 402) {
                console.log('payment required');
                toggleDialog('upgrade', true, error.response.data.message);
                return Promise.reject(error);
            }
            return Promise.reject(error);
        }
    );

    // Document CRUD
    async function getDocumentsAndFolders() {
        const { data } = await api.get<DocumentListDto>('/');
        return data;
    }

    async function getRecentDocuments() {
        const { data } = await api.get<Document[]>('?flat=true&limit=3');
        return data;
    }


    async function getDocumentsFlat(limit = 100) {
        const { data } = await api.get<Document[]>('?flat=true', {
            params: {
                limit
            }
        });
        return data;
    }

    async function createDocument(document: Partial<Document>) {
        const { data } = await api.post<Document>('/', document);
        return data;
    }

    async function getDocument(documentId: string) {
        const { data } = await api.get<Document>(`/${documentId}`);
        return data;
    }

    async function updateDocument(documentId: string, params: Partial<Document>) {
        const { data } = await api.patch<Document>(`/${documentId}`, params)
        return data;
    }

    async function getLatestVersion(documentId: string) {
        const { data } = await api.get<{ content: string, id: string, version: number }>(`/${documentId}/versions/latest`);
        return data;
    }

    async function moveDocument(documentId: string, newParentFolderId?: string | null) {
        const { data } = await api.patch<Document>(`/${documentId}`, { DocumentFolderId: newParentFolderId });
        return data;
    }

    async function getMembers(documentId: string) {
        const { data } = await api.get<any[]>(`/${documentId}/members`);
        return data;
    }

    async function addMember(documentId: string, email: string) {
        const { data } = await api.post(`/${documentId}/members`, { email, role: MemberRole.VIEWER });
        return data;
    }

    async function deleteMember(documentId: string, memberId: string) {
        await api.delete(`/${documentId}/members/${memberId}`);
    }

    async function deleteDocument(id: string) {
        await api.delete(`/${id}`)
    }

    async function searchDocuments(query: string) {
        const { data } = await api.get<HomeSearchResult[]>(`/search?q=${query}`);
        return data;
    }

    async function cloneDocument(documentId: string, documentName: string) {
        const { data } = await api.post<Document>(`/${documentId}/clone`, { name: documentName });
        return data;
    }

    // Document Chat CRUD
    async function listChats(documentId: string) {
        const { data } = await api.get<Chat[]>(`/${documentId}/chats`);
        return data;
    }

    async function createChat(documentId: string, chat: Partial<Chat>) {
        const { data } = await api.post<Chat>(`/${documentId}/chats`, chat);
        return data;
    }

    async function getChat(documentId: string, chatId: string) {
        const { data } = await api.get<Chat>(`/${documentId}/chats/${chatId}`);
        return data;
    }

    // Chat Messages CRUD
    async function listMessages(documentId: string, chatId: string) {
        const { data } = await api.get<MessageDto[]>(`/${documentId}/chats/${chatId}/messages`);
        return data;
    }

    async function createMessage(documentId: string, chatId: string, message: any) {
        const { data } = await api.post<MessageDto>(`/${documentId}/chats/${chatId}/messages`, message);
        return data;
    }

    // Image Attachment
    async function getUploadUrl(documentId: string, chatId: string) {
        const { data } = await api.post(`/${documentId}/chats/${chatId}/images`);
        return data.url;
    }


    // Sources CRUD
    async function listSources(documentId: string) {
        const { data } = await api.get<Source[]>(`/${documentId}/sources`);
        return data;
    }

    async function getSource(documentId: string, sourceId: string) {
        const { data } = await api.get<Source>(`/${documentId}/sources/${sourceId}`);
        return data;
    }

    async function createSource(documentId: string, source: any) {
        const { data } = await api.post<Source>(`/${documentId}/sources`, source);
        return data;
    }

    async function deleteSource(documentId: string, sourceId: string) {
        await api.delete(`/${documentId}/sources/${sourceId}`);
    }

    async function stopMessage(documentId: string, chatId: string) {
        await api.post(`/${documentId}/chats/${chatId}/stop`);
    }


    async function updateDocumentPreview(documentId: string) {
        await api.post(`/${documentId}/preview`);
    }

    // Websockets for Chat
    const connectToChat = (chatId: string): Socket => {
        let url = config.public.baseUrl;
        if (url == 'http://localhost:5173') url = 'https://staging.app.wobby.ai'
        const socket = io(url, {
            reconnection: true,
            secure: true,
            path: '/api/documents/socket.io',
            transports: ['websocket', 'polling'],
            query: {
                chatId: chatId
            }
        })

        return socket;
    }

    // Folder CRUD
    async function createFolder(folder: Partial<DocumentFolder>): Promise<DocumentFolder> {
        const { data } = await api.post<DocumentFolder>('/folders', folder);
        return data;
    }

    async function deleteFolder(folderId: string) {
        await api.delete(`/folders/${folderId}`);
    }

    async function updateFolder(folderId: string, folder: Partial<DocumentFolder>): Promise<DocumentFolder> {
        const { data } = await api.patch<DocumentFolder>(`/folders/${folderId}`, folder);
        return data;
    }

    async function moveFolder(folderId: string, newParentFolderId?: string | null): Promise<DocumentFolder> {
        const { data } = await api.patch<DocumentFolder>(`/folders/${folderId}`, { DocumentFolderId: newParentFolderId });
        return data;
    }

    async function getVisualizations(documentId: string) {
        const { data } = await api.get<any[]>(`/${documentId}/visualizations`);
        return data;
    }

    async function getUserImages() {
        const { data } = await api.get<Array<UserImage>>(`/user-images`);
        return data;
    }

    async function createUserImage(image: File) {
        const { data } = await api.post<{ url: string }>(`/user-images`);
        await api.put(data.url, image, {
            headers: {
                'x-ms-blob-type': 'BlockBlob',
                'Content-Type': image.type,
            }
        });
        return data;
    }


    async function getTable(documentId: string, chatId: string, tableId: string) {
        const { data } = await api.get<any>(`/${documentId}/chats/${chatId}/tables/${tableId}`);
        return data;
    }

    async function saveTableAsKnowledgeSource(documentId: string, tableId: string) {
        const { data } = await api.post<any>(`/${documentId}/chats/null/tables/${tableId}/save`);
        return data;
    }

    async function getTableReasoning(documentId: string, chatId: string, tableId: string) {
        const { data } = await api.get<any>(`/${documentId}/chats/${chatId}/tables/${tableId}/reasoning`);
        return data;
    }

    async function getStyleOptions() {
        const { data } = await api.get<StyleOption[]>('/null/chats/style-options');
        return data;
    }

    async function updateChat(documentId: string, chatId: string, chat: Partial<Chat>) {
        const { data } = await api.patch<Chat>(`/${documentId}/chats/${chatId}`, chat);
        return data;
    }

    async function updateVisualization(documentId: string, visualizationId: string, visualization: any) {
        const { data } = await api.patch<any>(`/${documentId}/visualizations/${visualizationId}`, visualization);
        return data;
    }

    /**
     * @deprecated This method will be removed in future versions. Use the new method instead.
     */
    async function getChatSelectedSources(documentId: string, chatId: string) {
        console.warn('getChatSelectedSources is deprecated and will be removed in future versions.');
        if (!chatId) return [];
        const { data } = await api.get<SelectedSource[]>(`/${documentId}/chats/${chatId}/selected-sources`);
        return data;
    }

    /**
     * @deprecated This method will be removed in future versions. Use the new method instead.
     */
    async function getChatSelectedSource(documentId: string, chatId: string, sourceId: string) {
        console.warn('getChatSelectedSource is deprecated and will be removed in future versions.');
        const { data } = await api.get<SelectedSource>(`/${documentId}/chats/${chatId}/selected-sources/${sourceId}`);
        return data;
    }

    /**
     * @deprecated This method will be removed in future versions. Use the new method instead. 
     */
    async function addSelectedSource(documentId: string, chatId: string, sourceId: string, options?: any) {
        console.warn('addSelectedSource is deprecated and will be removed in future versions.');
        await api.post(`/${documentId}/chats/${chatId}/selected-sources`, { sourceId: sourceId, options });
    }

    /**
     * @deprecated This method will be removed in future versions. Use the new method instead.
     */
    async function removeSelectedSource(documentId: string, chatId: string, sourceId: string) {
        console.warn('removeSelectedSource is deprecated and will be removed in future versions.');
        await api.delete(`/${documentId}/chats/${chatId}/selected-sources`, {
            params: {
                sourceId
            }
        });
    }

    async function getVariableReasoning(documentId: string, chatId: string, variableId: string) {
        const { data } = await api.get<any>(`/${documentId}/chats/${chatId}/variables/${variableId}/reasoning`);
        return data;
    }

    async function updateMessage(documentId: string, chatId: string, messageId: string, message: any) {
        const { data } = await api.put<any>(`/${documentId}/chats/${chatId}/messages/${messageId}`, message);
        return data;
    }

    async function awnserQuestion(documentId: string, chatId: string, messageId: string, questionId: string, answer: any) {
        const { data } = await api.put<any>(`/${documentId}/chats/${chatId}/messages/${messageId}/questions/${questionId}`, {
            answer,
        });
        return data;
    }

    async function getRelevantNodes(visualizationId: string, nodes: any[]) {
        const { data } = await api.post<{ relevantNodes: string[] }>(`/refresh/relevance`, { visualizationId, nodes });
        return data;
    }

    async function rewriteRelevantNodes(text: string, knowledgeSourceId: string, knowledgeSourceVersionId: string, query?: string) {
        const { data } = await api.post<{ updatedText: string }>(`/refresh/rewrite`, { text, knowledgeSourceId, knowledgeSourceVersionId, query });
        return data;
    }

    async function updateSource(documentId: string, sourceId: string, source: any) {
        const { data } = await api.patch<Source[]>(`/${documentId}/sources/${sourceId}`, source);
        return data;
    }

    async function getChatSources(documentId: string, chatId: string) {
        const { data } = await api.get<ChatSource[]>(`/${documentId}/chats/${chatId}/sources`);
        return data;
    }

    async function getChatSource(documentId: string, chatId: string, sourceId: string) {
        const { data } = await api.get<ChatSource>(`/${documentId}/chats/${chatId}/sources/${sourceId}`);
        return data;
    }

    async function addChatSource(documentId: string, chatId: string, props: Partial<ChatSource>) {
        const { data } = await api.post<ChatSource>(`/${documentId}/chats/${chatId}/sources`, props);
        return data;
    }

    async function removeChatSource(documentId: string, chatId: string, sourceId: string) {
        await api.delete(`/${documentId}/chats/${chatId}/sources/${sourceId}`);
    }

    async function updateChatSource(documentId: string, chatId: string, sourceId: string, props: Partial<ChatSource>) {
        const { data } = await api.patch<ChatSource>(`/${documentId}/chats/${chatId}/sources/${sourceId}`, props);
        return data;
    }

    return {
        updateChatSource,
        getChatSources,
        getChatSource,
        addChatSource,
        removeChatSource,
        updateSource,
        getChat,
        addMember,
        getMembers,
        deleteMember,
        getLatestVersion,
        getDocumentsAndFolders,
        getRecentDocuments,
        createDocument,
        getDocument,
        updateDocument,
        moveDocument,
        deleteDocument,
        listChats,
        createChat,
        listMessages,
        createMessage,
        getUploadUrl,
        listSources,
        getSource,
        createSource,
        deleteSource,
        stopMessage,
        connectToChat,
        createFolder,
        deleteFolder,
        updateFolder,
        moveFolder,
        getVisualizations,
        getUserImages,
        createUserImage,
        getTable,
        getTableReasoning,
        getStyleOptions,
        updateChat,
        updateVisualization,
        getChatSelectedSources,
        addSelectedSource,
        removeSelectedSource,
        getVariableReasoning,
        updateMessage,
        awnserQuestion,
        updateDocumentPreview,
        getChatSelectedSource,
        searchDocuments,
        cloneDocument,
        getRelevantNodes,
        rewriteRelevantNodes,
        saveTableAsKnowledgeSource,
        getDocumentsFlat
    }
}